import React, { memo } from 'react';

import { Stack, StackProps } from '@adc/parallax-component-library';

import { useMediaQuery } from 'Hooks';

/**
 * For mobile devices, this component fills the entire screen.
 * For desktop, this component is centralized and fills around 50% of the entire screen.
 *
 * @param IFlexProps
 */

const query = { minWidth: 480 };

const ResponsiveContainer = memo(({ ...rest }: StackProps) => {
  const [isDesktopScreen] = useMediaQuery(query);

  return (
    <Stack
      width={isDesktopScreen ? 500 : '100%'}
      height={isDesktopScreen ? 550 : '100%'}
      backgroundColor="$surface.container"
      borderRadius={isDesktopScreen ? 40 : 0}
      paddingHorizontal={isDesktopScreen ? 80 : 16}
      marginTop={isDesktopScreen ? 100 : 0}
      paddingTop={isDesktopScreen ? 24 : 75}
      alignSelf="center"
      {...rest}
    />
  );
});

ResponsiveContainer.displayName = 'ResponsiveContainer';

export { ResponsiveContainer };
