import { useEffect, useMemo, useState } from 'react';

type QueryKeys = 'maxWidth' | 'minWidth' | 'maxHeight' | 'minHeight' | 'orientation';

type SubQuery = {
  [key in QueryKeys]?: number | string;
};

type Query = SubQuery | SubQuery[];

function subQueryToMediaQueryString(subQuery: SubQuery): string {
  const queryParts = Object.entries(subQuery).map(([key, value]) => {
    let cssKey = '';

    switch (key) {
      case 'maxWidth':
        cssKey = 'max-width';
        break;
      case 'minWidth':
        cssKey = 'min-width';
        break;
      case 'maxHeight':
        cssKey = 'max-height';
        break;
      case 'minHeight':
        cssKey = 'min-height';
        break;
      case 'orientation':
        cssKey = 'orientation';
        break;
      default:
        return '';
    }

    if (cssKey === 'orientation') {
      return `(${cssKey}: ${value})`;
    } else {
      const unit = typeof value === 'number' ? 'px' : '';

      return `(${cssKey}: ${value}${unit})`;
    }
  });

  return queryParts.filter(Boolean).join(' and ');
}

export default function useMediaQuery(query: Query): boolean[] {
  const [matches, setMatches] = useState<boolean[]>([]);

  const queries = useMemo(() => {
    const subQueries = Array.isArray(query) ? query : [query];

    return subQueries.map(subQueryToMediaQueryString);
  }, [query]);

  useEffect(() => {
    if (typeof window === 'undefined' || !window.matchMedia) {
      return;
    }

    const mediaQueryLists = queries.map((q) => window.matchMedia(q));

    const updateMatches = () => {
      setMatches(mediaQueryLists.map((mql) => mql.matches));
    };

    updateMatches();

    const handlers = mediaQueryLists.map((mql, index) => {
      const handler = (event: MediaQueryListEvent) => {
        setMatches((prev) => {
          const updated = [...prev];

          updated[index] = event.matches;
          return updated;
        });
      };

      mql.addEventListener('change', handler);
      return () => mql.removeEventListener('change', handler);
    });

    return () => {
      handlers.forEach((cleanup) => cleanup());
    };
  }, [queries]);

  return matches;
}
