import React from 'react';

import { Text } from '@adc/parallax-component-library';
import { IconProps } from '@adc/parallax-icons';
import styled from 'styled-components';

type LinkProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  onPress?: () => void;
  disabled?: boolean;
  testID: string;
  IconElement?: React.NamedExoticComponent<IconProps>;
  linkTextColor?: any;
};

const BtnWrapper = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: 'center';
  cursor: pointer;
`;

const Link = ({
  children,
  onPress,
  disabled,
  testID,
  IconElement,
  linkTextColor = '$interactive.tertiary.enabled.text',
  ...rest
}: LinkProps) => {
  return (
    <BtnWrapper id={testID} onClick={onPress} disabled={disabled} {...rest}>
      {IconElement && (
        <IconElement color="$interactive.tertiary.enabled.text" testID={`${testID}-icon`} />
      )}
      <Text
        marginLeft="$2"
        fontSize="$bodyBase.strong"
        fontWeight="$bodyBase.strong"
        color={linkTextColor}
        testID={`${testID}-label`}
      >
        {children}
      </Text>
    </BtnWrapper>
  );
};

export { Link };
