import { getTheme } from 'Utilities/dataHelpers';

import { NAVIGATION, NavigationAction } from '../nav/types';
import { EnvActions, EnvState, SET_PHONE_LANGUAGE } from './types';

const initialState: EnvState = {
  theme: getTheme(),
  cid: '',
  locale: '',
  redirect_uri: '',
  app: 'none',
  country: 'US',
  phoneLanguage: '',
  pId: '',
};

export const envReducer = (
  state = initialState,
  action: NavigationAction | EnvActions
): EnvState => {
  switch (action.type) {
    case NAVIGATION:
      return {
        ...state,
        theme: action.context.query?.theme || state.theme,
        app: action.context.query?.app || state.app,
        country: action.context.query?.country || state.country,
        redirect_uri: action.context.query?.redirect_uri || state.redirect_uri,
        cid: action.context.query?.cid || state.cid,
        locale: action.context.query?.locale || state.locale,
        pId: action.context.query?.pId || state.pId,
      };
    case SET_PHONE_LANGUAGE:
      return {
        ...state,
        phoneLanguage: action.phoneLanguage,
      };
    default:
      return state;
  }
};
