enum InsuletStatusCode {
  SUCCESS = 0,
  CID_ALREADY_USED = 30,
  WRONG_REGION = 60,
  INTERNAL_ERROR = 15,
  FORGOT_PASSWORD = 50,
  LOCKOUT_ERROR = 45,
}

export default InsuletStatusCode;
