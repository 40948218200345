import React, { useEffect } from 'react';

import { Text, VStack } from '@adc/parallax-component-library';

import { usePartner } from 'Hooks';

import { Main } from 'Components/utility/Main';

import i18n from 'Utilities/i18n';

import { LogoActionBar } from '../ActionBar';

const ErrorPage: React.FC = () => {
  const { partner } = usePartner();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(partner.website);
    }, 5000);
  }, [partner]);

  return (
    <Main>
      <LogoActionBar testID="ErrorPage" />
      <VStack
        padding="$4"
        backgroundColor="$surface.background"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        zIndex={1}
        style={{
          height: '100vh',
          width: '100vw',
        }}
      >
        <Text
          testID="ErrorPage.errorLoading-title"
          marginBottom="$2"
          color="$text.100"
          fontWeight="$h3.strong"
          fontSize="$h3.strong"
        >
          {i18n.t('Global.modals.errorLoading.title')}
        </Text>
        <Text
          testID="ErrorPage.errorLoading-body"
          color="$text.100"
          fontWeight="$bodyBase.default"
          fontSize="$bodyBase.default"
        >
          {i18n.t('Global.modals.errorLoading.body', { partnerName: partner.displayName })}
        </Text>
      </VStack>
    </Main>
  );
};

export default ErrorPage;
