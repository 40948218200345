export const SET_PHONE_LANGUAGE = 'SET_PHONE_LANGUAGE';

export interface EnvState {
  theme: 'polarisLight' | 'polarisDark' | 'otcLight' | 'otcDark';
  locale: string;
  redirect_uri: string;
  cid: string;
  app: string;
  country: string;
  phoneLanguage: string;
  pId: string;
}

interface SetPhoneLanguageAction {
  type: typeof SET_PHONE_LANGUAGE;
  phoneLanguage: string;
}

export type EnvActions = SetPhoneLanguageAction;
