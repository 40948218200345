import jwtDecode from 'jwt-decode';
import page from 'page';

import { logInfo } from 'Utilities/appCommunicator';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { persistAuthSession } from 'Utilities/session';

import { handlePaths } from './router_paths';

/**
 * https://visionmedia.github.io/page.js/
 */

/**
 * Blocked Navigation
 * To implement blocked navigation, you can read the comment on the router:blockNavigation subscription
 * This comment covers the history api logic that is used to pull this off.
 *
 * First of all, the browser history doesn't just keep track of the URLs you've visited, it's also able
 * to keep track of state related to it. There are two main methods for dealing with this:
 * pushState() - this method will add a new entry to the browser's history (with state) and update the
 * address bar accordingly, but it will not cause the browser to make a request. If you were to navigate
 * manually to a new page and then hit the browser's back button, it would go to this added entry.
 * replaceState() - This method is very similar except it will modify the current entry of the history.
 * This allows you to add state and even change the url in the history.
 *
 * Page-js primarily uses these APIs to manage things. In fact it adds a state object to every navigation
 * that it handles.
 *
 * What I've done below is ensure that every navigation that we handle has a property `tracked: true` added
 * to the state. (note that to do this I use Page-js's .save() method on the context object in the initial
 * load ONLY, it seems that on consecutive navigation handling Page-js will do pushState() or replaceState
 * for us).
 *
 * Then, in our logic to block navigation, we can look at the incoming context and check if it's been tracked.
 * If it has then the intention is clearly that the user has performed a browser back, and therefore is using
 * and older history entry. In that case we use this old context to pushState(), meaning we just added a new
 * entry on the history. If there is no tracked, that means the user is trying to navigate somewhere new, which
 * also means it has not gotten into the browser's history yet.
 *
 * Next, we use Page-js to do a replaceState() for us, and we use the path and state of the last navigation
 * context that was allowed to happen (the last one that wasn't blocked). So, in the case that the user tried
 * to go back, we had added a new entry and we now replace it with the path and state that we don't want to leave.
 * If the user had tried to go somewhere new, there isn't an entry yet, so we just need to replace this incoming
 * context with the current position and therefore the navigation will not occur.
 *
 * If I'm honest, I completely understand the logic to deal with going backwords and know that it's solid.
 * I have no idea why navigating somewhere new isn't causeing a problem via adding entries to the history...
 * but i'll take what i can get right now
 */

interface BlockNavigation {
  action?: (path: string, isBackNavigation: boolean) => boolean;
  blocked: boolean;
}

const _blockNavigation = {
  blocked: false,
} as BlockNavigation;

const _createQueryString = (query: any) => {
  if (!query) {
    return '';
  }

  const q = Object.entries(query)
    .map((q: any) => q.map((qq: any) => encodeURIComponent(qq)).join('='))
    .join('&');

  return q ? '?' + q : '';
};

let _previousLocation: { canonicalPath: string; state: any };

let initialized = false;
let titleReset = false;

// const hasCountrylang = () => {
//   const {
//     auth: { countrySelected },
//   } = store.getState();
//   const chosenCountry = getObject('chosenCountryLang');

//   return countrySelected || (chosenCountry && chosenCountry.country);
// };

// This captures all navigation and publishes a mediator message to
// page: and then appends the path of the navigation.
// this will also pass information of the navigation which includes the query
// params
page('*', (ctx: PageContext, next: () => void) => {
  // querystring on 'ctx' is already decoded, so we want to get the query string directly from the main path
  const querystring = ctx.querystring ? ctx.canonicalPath.split('?')[1].split('#')[0] : '';

  if (querystring) {
    const params = new URLSearchParams(querystring);

    ctx.query = Object.fromEntries(params);
  }

  ctx.handled = true;
  ctx.timing = new Date();

  // logic to deal with blocking navigation
  if (_blockNavigation.blocked) {
    _blockNavigation.blocked = false;
    const blocked =
      _blockNavigation.action && _blockNavigation.action(ctx.canonicalPath, !!ctx.state.tracked);

    if (blocked) {
      if (ctx.state.tracked) {
        ctx.pushState();
      }

      page.replace(_previousLocation.canonicalPath, _previousLocation.state, undefined, false);
      next();
      return;
    }
  }

  _previousLocation = ctx;
  ctx.state.tracked = true;

  // we want to convert the url path into a mediator path that uses ':'
  let eventPath = 'page' + ctx.canonicalPath.replace(/\//g, ':');

  eventPath = eventPath.split('?')[0];
  // Add identifier for root page event

  if (eventPath === 'page:') {
    eventPath += '_root';
  }

  // const modalAlive = store.getState().modal;

  // if (modalAlive && modalAlive.modalType) {
  //   mediator.publishAndWait('modal:hide');
  // }

  // This is logic that will run the very first time that the site loads (user enters url in the address
  // bar or refreshes the browser)
  if (!initialized) {
    // Handle outdated LV redirect. Match when we have targeted a versioned index.html directly
    // e.g. /1.0.0/index.html?redirect_uri=%2Fdashboard
    if (/^\/\d+\.\d+\.\d+\/index(\.html)?\/?$/.test(ctx.canonicalPath.split('?')[0])) {
      page.redirect(ctx.query?.redirect_uri || '/');
      return;
    }

    initialized = true;

    // Pass through query string creator for use in router path handlers
    ctx._createQueryString = _createQueryString;

    if (ctx.query?.token) {
      // store this as the session so we know who the user is
      try {
        const authToken = ctx.query.token;
        const decodedToken = jwtDecode<{ id: string; did: string; exp: number; iat: number }>(
          authToken
        );

        persistAuthSession({
          access_token: authToken,
          account_id: decodedToken.id,
          expires: decodedToken.exp,
          cid: '',
          app: '',
        });
      } catch (e) {
        log.error('failed to parse token: ', e);
      }
    }

    ctx.save();

    Promise.resolve(handlePaths(ctx))
      .then(() => {
        mediator.publish('app:ready', { state: true, router: true });
        mediator.publishAndWait(eventPath, ctx);
      })
      .catch((e) => log.error('Failed to find valid starting entry', e));
    return;
  }

  // this is logic that may run to handle race condition between i18n and router loading initially
  if (titleReset) {
    titleReset = false;
    ctx.save();
    return;
  }

  mediator.publishAndWait(eventPath, ctx);
  next();
});

const init = () => {
  page({ click: true });

  // // Let the app know the router is ready to be used
  // window.setTimeout(() => {
  //   mediator.publish('app:ready', { router: true });
  // }, 0);
};

// Want to wait until the store is ready to do this, otherwise a chain of events will
// happen that will end with trying to use the store to dispatch before there is a store
mediator.once('store:created', init);

// We reinitialize the router so that we can trigger `ctx.save()`, which refreshes the
// document title on android. This handler is called after i18n has finished init
mediator.once('init:router', () => {
  titleReset = true;
  page({ click: true });
});

// subscribe to allow our code to submit a navigation request
mediator.subscribe('router:navigate', (url: string | { url: string; query: any }) => {
  if (typeof url === 'string') {
    page(url);
    return;
  }

  page(url.url + _createQueryString(url.query));
});

// subscribe to allow our code to submit a navigation request
mediator.subscribe('router:redirect', (url: string) => {
  page.redirect(url);
});

// refreshes the browser...
mediator.subscribe('router:reload', () => {
  window.location.reload();
});

mediator.subscribe('router:back', () => {
  // This is used by the app to handle the back button
  logInfo('adc-webview:navigation');

  window.history.back();
});

/**
 * To block navigation, you need to publish to this with a function that you want executed if a navigation
 * occurs.
 * The action will be passed the path that the user intended to navigate to, in case you want to take them
 * there. It will also be passed a flag letting you know if the navigation is a back navigation.
 * This will only block one time, so if you allow the user to stay where they are after blocking them, but
 * want to block them again, you'll need to publish again after the block. Checkout the implementation for
 * linking LibrePro 1-time uploads from the report viewer if you want an example.
 * Your action must return a boolean confirming you want the navigation to be blocked.
 */
mediator.subscribe(
  'router:blockNavigation',
  (action: (path: string, isBackNavigation: boolean) => boolean) => {
    _blockNavigation.blocked = true;
    _blockNavigation.action = action;
  }
);
