import { _config } from 'Utilities/envConfig';
import log from 'Utilities/log';

export type Topic = 'adc-webview:signin' | 'adc-webview:navigation' | 'adc-webview:create-account';

export const logInfo = <T>(topic: Topic, data?: T): void => {
  const message = JSON.stringify({ topic, data });

  if (_config.environment !== 'production') {
    log.info(message);
  }
};
