import { AUTH_SESSION, AuthActions, AuthState, LOGOUT } from './types';

const initialState: AuthState = {
  access_token: '',
  account_id: '',
  expires: 0,
  cid: '',
  app: '',
};

export const authReducer = (state = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AUTH_SESSION:
      return {
        ...state,
        ...action.authSession,
      };
    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
