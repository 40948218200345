import log from 'Utilities/log';

import { setSessionObject } from './storage';

// TODO: change how to add the connections
const setConnections = (connections: { name: string; email: string; connected: boolean }[]) => {
  setSessionObject('connections', connections);
  log.info('Success! Refresh your page.');
};

declare global {
  interface Window {
    testHelper: {
      setConnections: typeof setConnections;
    };
  }
}

window.testHelper = {
  setConnections,
};
