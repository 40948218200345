import React from 'react';

import { Stack, StackProps, Text } from '@adc/parallax-component-library';

type HeaderProps = StackProps & {
  title: string;
  subtitle?: string;
  testID?: string;
  visible?: boolean;
  textAlign?: 'auto' | 'center';
};

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  testID,
  visible = true,
  textAlign,
  ...rest
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Stack paddingHorizontal="$4" {...rest}>
      <Text
        testID={`${testID}-title`}
        textAlign={textAlign}
        fontSize="$h1.strong"
        fontWeight="$h1.strong"
        marginTop="$4"
      >
        {title}
      </Text>
      <Text
        testID={`${testID}-subtitle`}
        textAlign={textAlign}
        fontSize="$bodyBase.default"
        fontWeight="$bodyBase.default"
        color="$text.100"
        marginTop="$2"
      >
        {subtitle}
      </Text>
    </Stack>
  );
};

export { Header };
