import { INITIALIZATION_ERROR } from 'Reducers/nav/types';

import { store } from 'Utilities/store';

import { updateConfig } from 'Services/config';

const root = (pageCtx: PageContext): Promise<void> => {
  return new Promise((resolve) => {
    const queryParams = pageCtx.query;

    const { country, app, pId, redirect_uri } = queryParams || {};

    updateConfig(country, app, pId)
      .then(resolve)
      .catch((err) => {
        setupInitializationErrorLogic(resolve, err.status, err.code, redirect_uri);
      });
  });
};

const setupInitializationErrorLogic = (
  resolve: () => void,
  errorStatus: number,
  errorCode: string,
  redirect_uri?: string
) => {
  if (store) {
    store.dispatch({
      type: INITIALIZATION_ERROR,
      initErrorStatus: errorStatus,
      initErrorCode: errorCode,
      redirect_uri,
    });
  }

  resolve();
};

// const protectedPageAction = () => {
//   return new Promise<void>((resolve) => {
//     resolve();
//   });
// };

// Create your paths that you want to handle here
// you want to most specific earlier and the least specific later
// Note: if one action throws an error (calls promise reject) then the handlePaths
// will move onto the next matching route
const routeActions: Array<routeAction> = [
  {
    regex: [/^\.*/],
    action: root,
  },
];

type routeAction = {
  regex: Array<RegExp>;
  action: routeActionFn;
};

type routeActionFn = (ctx: PageContext) => Promise<void>;

export const handlePaths = (ctx: PageContext): Promise<void> => {
  const doActions: Array<routeActionFn> = [];

  for (const check of routeActions) {
    for (const r of check.regex) {
      if (ctx.canonicalPath.match(r)) {
        // Add valid actions to chain later
        doActions.push(check.action);
      }
    }
  }

  // Start with a rejected promise
  let p = Promise.reject<void>();

  // Build a negative promise chain that will try the next action if the previous one failed
  // Once an action succeeds the chain ends and the app starts
  for (let i = 0; i < doActions.length; i++) {
    p = p.catch(() => doActions[i](ctx));
  }

  return p;
};
