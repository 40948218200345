import React, { memo } from 'react';

import { Input as InputParallax, InputProps } from '@adc/parallax-component-library';

import { InputErrorField } from 'Components/utility';

type Props = {
  isInvalid?: boolean;
  label: string;
  errorMessage?: string;
  placeholder?: string;
};

const Input = memo(
  ({
    isInvalid = false,
    label = '',
    errorMessage,
    placeholder = '',
    value,
    onBlur,
    onChangeText,
    disabled,
    testID,
    inputMode,
    onFocus,
  }: Props & InputProps) => {
    return (
      <>
        <InputParallax
          inputMode={inputMode}
          aria-label={label}
          label={label}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          error={isInvalid}
          onChangeText={onChangeText}
          value={value}
          testID={testID}
          disabled={disabled}
        />
        {isInvalid && errorMessage && (
          <InputErrorField errorMessage={errorMessage} testID={testID} />
        )}
      </>
    );
  }
);

Input.displayName = 'Input';

export { Input };
