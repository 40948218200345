import React, { useCallback, useMemo, useState } from 'react';

import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import ResetPasswordLinkNotValid from './error/ResetPasswordLinkNotValid';
import ResetPasswordFirstStep from './steps/ResetPasswordFirstStep';
import ResetPasswordSecondStep from './steps/ResetPasswordSecondStep';

const ResetPassword: React.FC = () => {
  const [showTokenExpirationError, setShowTokenExpirationError] = useState(false);

  const makeSteps = useMemo(() => {
    return [
      {
        component: ResetPasswordFirstStep,
        componentName: 'ResetPasswordFirstStep',
      },
      {
        component: ResetPasswordSecondStep,
        componentName: 'ResetPasswordSecondStep',
      },
    ];
  }, []);

  const handleTokenExpirationError = useCallback(() => {
    setShowTokenExpirationError(true);
  }, []);

  if (showTokenExpirationError) {
    return <ResetPasswordLinkNotValid />;
  }

  return (
    <React.Fragment>
      <WizardMainContainer>
        <Wizard steps={makeSteps} handleTokenExpirationError={handleTokenExpirationError} />
      </WizardMainContainer>
    </React.Fragment>
  );
};

export default ResetPassword;
