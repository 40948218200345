import React from 'react';

import { Button, Text, VStack } from '@adc/parallax-component-library';

import { Footer, Main, Spinner, TitleActionBar } from 'Components/utility';
import { NavigationProps } from 'Components/wizard';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

export interface FormValues {
  email: string;
}

type Props = {
  values: FormValues;
  handleSubmitForgotPass(email: string): Promise<void>;
  handleOnDoneClick: () => void;
  showForgotPasswordConfirmatioMessage: () => void;
  loading: boolean;
} & NavigationProps<FormValues>;

const ForgotPassSecondStep: React.FC<Props> = ({
  values,
  handleSubmitForgotPass,
  handleOnDoneClick,
  showForgotPasswordConfirmatioMessage,
  loading,
  back,
}) => {
  const handleResendEmail = () => {
    handleSubmitForgotPass(values.email)
      .then(showForgotPasswordConfirmatioMessage)
      .catch((err) => {
        log.error(err);
      });
  };

  return (
    <Main>
      <TitleActionBar
        onBack={back}
        testID="ForgotPassword"
        title={i18n.t<string>('CheckYourEmail.title')}
      />
      {loading && <Spinner />}
      <VStack flex={1}>
        <Text
          testID="ForgotPassword.title"
          textAlign="auto"
          fontSize="$bodyBase.default"
          fontWeight="$bodyBase.default"
          color="$text.100"
          padding="$4"
        >
          {i18n.t<string>('CheckYourEmail.subtitle')}
        </Text>
        <Text
          paddingHorizontal="$4"
          testID="ForgotPassword.user-email"
          color="$text.100"
          fontSize="$bodyBase.strong"
          fontWeight="$bodyBase.strong"
        >
          {values.email}
        </Text>
        <VStack justifyContent="center" alignItems="center" marginTop="$4">
          <img
            src={i18n.t<string>('CheckYourEmail.content.checkYourEmail.media.checkYourEmail')}
            alt=""
            style={{
              objectFit: 'contain',
              height: '70%',
            }}
            id="ForgotPassword.check-email-img"
          />
          <Text
            testID="ForgotPassword.notReceiveEmail"
            color="$text.100"
            fontSize="$bodyBase.default"
            fontWeight="$bodyBase.default"
            marginTop="$4"
          >
            {i18n.t<string>('CheckYourEmail.content.checkYourEmail.primaryText')}
          </Text>
          <Button
            variant="tertiary"
            testID="ForgotPassword.resendEmail-btn"
            onPress={handleResendEmail}
            label={i18n.t<string>('CheckYourEmail.content.checkYourEmail.links.resendEmail.label')}
            aria-label={i18n.t<string>(
              'CheckYourEmail.content.checkYourEmail.links.resendEmail.label'
            )}
          />
        </VStack>
      </VStack>
      <Footer
        buttonText={i18n.t<string>('CheckYourEmail.content.checkYourEmail.links.done.label')}
        onButtonSubmit={handleOnDoneClick}
        testIDButton="ForgotPassword.done-btn"
      />
    </Main>
  );
};

export default ForgotPassSecondStep;
