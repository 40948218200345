import React, { ReactElement } from 'react';
import { Trans, TransProps } from 'react-i18next';

import { Text } from '@adc/parallax-component-library';

import { useDefaultTransComponents } from 'Hooks';

import i18n from 'Utilities/i18n';

export interface RichTextProps extends TransProps<''> {
  text: string;
  values?: Record<string, string | number>;
  testID?: string;
  onPressLink?: (href: string) => void;
  customNodes?: { strong?: ReactElement; a?: ReactElement };
}

const RichText = ({
  text,
  values = {},
  onPressLink,
  customNodes,
  testID,
  ...rest
}: RichTextProps) => {
  const defaultTransComponents = useDefaultTransComponents(onPressLink, customNodes);

  return (
    <Trans
      i18n={i18n}
      defaults={text}
      values={values}
      parent={({ children }: any) => (
        <Text
          testID={testID}
          color="$text.100"
          fontSize="$bodyBase.default"
          fontWeight="$bodyBase.default"
        >
          {children}
        </Text>
      )}
      components={defaultTransComponents}
      {...rest}
    />
  );
};

export { RichText };
