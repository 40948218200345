import { LOGOUT } from 'Reducers/auth/types';

import log from 'Utilities/log';
import { clearCookie, clearSession, getSessionObject, setSessionObject } from 'Utilities/storage';

import mediator from './mediator';
import { store } from './store';

export const persistAuthSession = (session: AuthSession): void => {
  try {
    setSessionObject('authSession', session);
  } catch (err) {
    log.error(err);
  }
};

export const getAuthSession = (): AuthSession | null => {
  try {
    const authSession = getSessionObject<AuthSession>('authSession');

    return authSession;
  } catch (e) {
    return null;
  }
};

export const logout = () => {
  clearSession();
  clearCookie('authSession');
  store.dispatch({ type: LOGOUT });
  mediator.publish('router:navigate', '/login');
};
