import { CONFIG_UPDATE } from 'Reducers/config/types';

import { setUrls, urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { getSessionObject, setSessionObject } from 'Utilities/storage';
import { store } from 'Utilities/store';

export const getConfig = (country: string, app: string, pId: string) => {
  return new Promise<Config>((resolve, reject) => {
    mediator
      .request<ApiResponse<Config>>('api:invoke', {
        method: 'GET',
        url: urls.libreSharingApiUrl + 'config',
        headers: {
          'X-User-Agent': app,
        },
        params: {
          country,
          pId,
        },
      })
      .then((result) => {
        if (result.data) {
          return resolve(result.data);
        }

        reject(result);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const updateConfig = (country = 'US', app = '', pId = '') => {
  return new Promise<void>((resolve, reject) => {
    const sessionConfig = getSessionObject<Config>('config');

    if (sessionConfig) {
      store.dispatch({ type: CONFIG_UPDATE, config: sessionConfig });
      resolve();
      return;
    }

    getConfig(country, app, pId)
      .then((config) => {
        store.dispatch({ type: CONFIG_UPDATE, config });

        setUrls(config);

        setSessionObject('config', config);

        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
