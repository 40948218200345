// this (wdyr) MUST be loaded first in order for it to do it's magic
// import './wdyr';
// This should be changed by babel to only the imports that are needed by our target
import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@adc-polaris-component-library/component-library';
import { ParallaxProvider, config } from '@adc/parallax-component-library';
import 'core-js/stable';

import { Spinner } from 'Components/utility';
import ConfigVersionMessage from 'Components/utility/ConfigVersionMessage';
import InitializationErrorPage from 'Components/utility/InitializationErrorPage';

// Imports our global styles. This must be imported first before any components or
// other styles to ensure it comes first in the compiled css chunk
import 'Styles/custom.scss';

import { setUrls } from 'Utilities/dataHelpers';
import { _config, getEnvConfig } from 'Utilities/envConfig';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { createStore } from 'Utilities/store';
import 'Utilities/testHelper';

import App from './App';
import './index.scss';
// Import the services via the index file in that folder
import './services';

// if (process.env.NODE_ENV !== 'production') {
//   import('./dev');
//   App.whyDidYouRender = true;
// }
let gotEnvConfig = false;
let store: ReturnType<typeof createStore>;
let root: Root;
// This is the starting point of our app launch life-cycle
const init = () => {
  const container = document.getElementById('app');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  root = createRoot(container!);
  store = createStore();
  renderLoading();
  getEnvConfig()
    .then((config) => {
      gotEnvConfig = true;
      log.init(config.logLevel);

      if (config.mock) {
        mediator.once('MOCK:setup:intialized', () => initializeStore(config));
        // This import would allow you to have the mock code ready to go
        import('./services/mock');
      } else {
        initializeStore(config);
      }
    })
    .catch((err: Error) => {
      log.error('failed to get the environment config: ' + err);
      renderError(new Error('failed to get the environment config: ' + err));
    });
};
const renderLoading = () => {
  window.setTimeout(() => {
    root.render(
      <ParallaxProvider config={config} defaultTheme="light" locale="en-US" dir="ltr">
        <Spinner />
      </ParallaxProvider>
    );
  }, 0);
};
// Renders our UnexpectedError display and minimally bootstraps i18n
const renderError = (err: Error) => {
  // since there was an error, need to initialize services anyway
  mediator.publish('init:i18n');
  window.setTimeout(() => {
    root.render(
      <ThemeProvider app={'polarisLight'}>
        <ParallaxProvider config={config} defaultTheme="light" locale="en-US" dir="ltr">
          <div style={{ height: '100vh' }}>
            <div>
              {process.env.NODE_ENV !== 'production' && <span>{err.toString()}</span>}
              {gotEnvConfig && <ConfigVersionMessage />}
            </div>
            <InitializationErrorPage />
          </div>
        </ParallaxProvider>
      </ThemeProvider>
    );
  }, 0);
};
// Finally render our React trees
const renderApp = () => {
  // This timeout is added to try and deal with the translations not being ready when the site loads (only
  // seen as an issue in IE, of course). if we think we're still seeing that issue then remove this timeout
  // when a different solution is found
  window.setTimeout(() => {
    try {
      root.render(
        <Provider store={store}>
          <ThemeProvider app={'polarisLight'}>
            <ParallaxProvider config={config} defaultTheme="light" locale="en-US" dir="ltr">
              <App />
            </ParallaxProvider>
          </ThemeProvider>
        </Provider>
      );
    } catch (e) {
      if (e instanceof Error) {
        renderError(e);
      }

      log.error(e);
    }
  });
};
// Add code here that you would like to do when you have the env config ready
// This is likely going to be lagic to attempt getting an existing session
// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
const initializeStore = (envConfig: typeof _config) => {
  setUrls(envConfig);
  store = createStore({ config: envConfig });
  mediator.publish('store:created');
};

// Begin
init();
// Services that need to publish that they are ready
const appReady = {
  i18n: false,
  router: false,
  // state would be useful to track if redux store is ready
  // state: false,
};
// Listens for all services to be complete so that we have everything we need when we mount React
const readySub = mediator.subscribe('app:ready', (ready: Partial<typeof appReady>) => {
  Object.assign(appReady, ready);
  const done = Object.values(appReady).every((value) => !!value);

  // i18n sets the document title, so we tell our router to 'refresh' to get the
  // document title to update on Android
  if (ready.i18n) {
    mediator.publish('init:router');
  }

  if (done) {
    mediator.publish('app:mounted');
    mediator.remove('app:ready', readySub.id);
  }
});

// This block will only be called when the page is fully ready
// right now that means that our router service has spun up and done its initial work (redirecting if necessary)
// and the nav service has updated the store to reflect that.
// THEN 'app:ready' is published. otherwise the components may erroneously read the location information
// from the store (for example, the component may try reading it before the nav service has updated it, this
// was a problem fairly consistent with IE)
mediator.once('app:mounted', renderApp);
