import React, { useCallback, useMemo, useState } from 'react';

import { Modal } from '@adc-polaris-component-library/component-library';

import { InsuletStatusCode } from 'Enums';

import { Main } from 'Components/utility';

import i18n from 'Utilities/i18n';

type Props = {
  errorStatus?: number;
  errorCode?: string;
  redirect_uri?: string;
};

type Error = {
  title: string;
  message: string;
};

const InitializationErrorPage: React.FC<Props> = ({ redirect_uri }) => {
  const [isOpen, setIsOpen] = useState(true);

  const error: Error = useMemo(() => {
    return {
      title: 'Global.modals.errorCommunicatingServer.title',
      message: 'Global.modals.errorCommunicatingServer.body',
    };
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
    window.location.replace(`${redirect_uri}?errorcode=${InsuletStatusCode.INTERNAL_ERROR}`);
  }, [redirect_uri]);

  return (
    <Main>
      <Modal isOpen={isOpen}>
        <Modal.Header testID={error.title}>{i18n.t<string>(error.title)}</Modal.Header>
        <Modal.Body>
          <Modal.BodyText testID={error.message} nativeID={error.message}>
            {i18n.t<string>(error.message)}
          </Modal.BodyText>
        </Modal.Body>
        <Modal.ButtonContainer>
          <Modal.Button testID="Global.microcopy.common.okCap" onPress={onClose}>
            {i18n.t<string>('Global.microcopy.common.okCap')}
          </Modal.Button>
        </Modal.ButtonContainer>
      </Modal>
    </Main>
  );
};

export default InitializationErrorPage;
