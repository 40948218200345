import { urls } from 'Utilities/dataHelpers';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const verifyCid = (
  cid: string,
  country: string,
  redirect_uri: string,
  accountId: string
) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'insulet/cid/' + accountId,
        data: {
          cid,
          country,
          redirectUrl: redirect_uri,
        },
      })
      .then(() => resolve())
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const createConnection = (cid: string, redirect_uri: string, accountId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'insulet/createconnection/' + accountId,
        data: {
          cid,
          redirectUrl: redirect_uri,
        },
      })
      .then(() => resolve())
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
